const themes = {
  SORTEOS_HERRADURA_ROJA: {
    COLORS: {
      primary: "#e1251b",
      secondary: "#fde576",
      black: "#212427",
      white: "#FFFFFF",
      light: "#ebedef",
      success: "#2eb85c",
      info: "#3399ff",
      warning: "#f9b115",
      brown: "#8e481f",
      gray: "#808080",
    },
    TAMAÑOS: {
      margincontainer: 20,
      radiusboton: 10,
    },
    IMAGENES: {
      logo: "/assets/brand/LogotipoOficial.png",
      logoCentral: "/assets/brand/VersionCondensadaOficial.png",
    },
    PERFIL: {
      proveedor: "Sorteos Herradura Roja",
    },
  },
  RIFAS_LOS_CHINOS: {
    COLORS: {
      primary: "#7DFF34",
      secondary: "#F2BF29",
      black: "#212427",
      white: "#FFFFFF",
      light: "#ebedef",
      success: "#2eb85c",
      info: "#3399ff",
      warning: "#f9b115",
      brown: "#24446B",
      gray: "#808080",
    },
    TAMAÑOS: {
      margincontainer: 20,
      radiusboton: 10,
    },
    IMAGENES: {
      logo: "/assets/rifasloschinos/rifasloschinos.png",
      logoCentral: "/assets/rifasloschinos/rifasloschinos.png",
    },
    PERFIL: {
      ID: "O9Q7mlMpykfHGfJLzn51JvWZZ0D2",
      whatsapp: "+526623662626",
      proveedor: "Rifas Los Chinos",
    },
  },
  DRIP_KICKS: {
    COLORS: {
      primary: "#4FAC6B",
      secondary: "#4FAC6B",
      black: "#212427",
      white: "#FFFFFF",
      light: "#ebedef",
      success: "#2eb85c",
      info: "#3399ff",
      warning: "#f9b115",
      brown: "#292929",
      gray: "#808080",
    },
    TAMAÑOS: {
      margincontainer: 20,
      radiusboton: 10,
    },
    IMAGENES: {
      logo: "/assets/dripkicks/dripkicks.png",
      logoCentral: "/assets/dripkicks/dripkicks.png",
    },
    PERFIL: {
      ID: "",
      whatsapp: "+526623662626",
      proveedor: "Drip Kicks",
    },
  },
  SORTEOS_RIFAS_EL_GUERO: {
    COLORS: {
      primary: "#e1251b",
      secondary: "#212427",
      black: "#212427",
      white: "#FFFFFF",
      light: "#ebedef",
      success: "#2eb85c",
      info: "#3399ff",
      warning: "#f9b115",
      brown: "#e1251b",
      gray: "#808080",
    },
    TAMAÑOS: {
      margincontainer: 20,
      radiusboton: 10,
    },
    IMAGENES: {
      logo: "/assets/sorteosrifaselguero/sorteosrifaselguero.png",
      logoCentral: "/assets/sorteosrifaselguero/sorteosrifaselguero.png",
    },
    PERFIL: {
      ID: "lUEW5x9JXmTDBNaKA984uJ5LQ4n2",
      whatsapp: "+529994108792",
      proveedor: "Sorteos y Rifas el Guero",
    },
  },
};

// Selección dinámica del tema según la variable de entorno
const selectedTheme = process.env.REACT_APP_THEME || "SORTEOS_HERRADURA_ROJA";
const theme = themes[selectedTheme] || themes.SORTEOS_HERRADURA_ROJA;

// Función para generar variables CSS
export const injectCSSVariables = () => {
  const root = document.documentElement;

  // Asignar colores como variables CSS
  Object.entries(theme.COLORS).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });

  // Asignar tamaños como variables CSS
  Object.entries(theme.TAMAÑOS).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, `${value}px`);
  });
};

// Exportar dinámicamente las constantes
export const COLORS = theme.COLORS;
export const TAMAÑOS = theme.TAMAÑOS;
export const IMAGENES = theme.IMAGENES;
export const PERFIL = theme.PERFIL;

const Fuentes = { COLORS, TAMAÑOS, IMAGENES, PERFIL };

export default Fuentes;
