import React, { Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import {
  getContacto,
  getMantenimientoStatus,
  getNotificacionesStatusRealTime,
  obtenerRegistroxIDTiempoReal,
  obternerRegistroxID,
  signInAnonymouslyWithFirebase,
  validarSesion,
} from "./utils/Acciones";
import LoadingHome from "./views/pages/loadingHome/LoadingHome";
import Confetti from "react-confetti";
import LoadingProcess from "./components/LoadingProcess";
import RecuperarContraseña from "./views/pages/recuperarContraseña/RecuperarContraseña";
import { getDoc } from "firebase/firestore";
import {
  calcularDiasRenovacionMembresia,
  isSorteosHerraduraRojaTheme,
  obtenerNumeroDeContacto,
  obtenerUbicacionActual,
} from "./utils/Utils";
import "src/styles/fonts.css";
import "src/styles/buttonMagic.css";
import "src/styles/textBorder.css";
import { mostrarMensajeError, mostrarMensajePregunta } from "./utils/Mensajes";
import BlockedUserScreen from "./views/pages/blockedUserScreen/BlockedUserScreen";
import MantenimientoScreen from "./views/pages/mantenimientoScreen/MantenimientoScreen";
import HelmetMetaData from "./components/core/HelmetMetData";
import { size } from "lodash";
import { COLORS, injectCSSVariables, PERFIL } from "./constants/theme";
// import { getToken, onTokenChanged } from "firebase/app-check";
// import { appCheck } from "./utils/Firebase";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [rol, setRol] = useState(null);
  const [membresia, setMembresia] = useState(null);
  const [ubicacion, setUbicacion] = useState(null);
  const [diasRenovacionMembresia, setDiasRenovacionMembresia] = useState(null);
  const [notificaciones, setNotificaciones] = useState(null);
  const [mantenimiento, setMantenimiento] = useState(null);
  // const [isAppCheckValid, setIsAppCheckValid] = useState(null);

  const colors = [COLORS.primary, COLORS.secondary, COLORS.brown];

  useEffect(() => {
    injectCSSVariables(); // Aplicar variables CSS globales
  }, []);

  useEffect(() => {
    if (isSorteosHerraduraRojaTheme()) {
      obtenerContacto();
    } else {
      obtenerProveedorPorId();
    }
  }, []);

  const obtenerProveedorPorId = async () => {
    try {
      const usuario = await obternerRegistroxID("USUARIOS", PERFIL.ID);

      if (!usuario?.data) {
        throw new Error("No se encontró información del proveedor.");
      }

      const { informacionContacto, phoneNumber } = usuario.data;
      const numeroDeContacto = obtenerNumeroDeContacto(
        informacionContacto?.numerosWhatsapp,
        informacionContacto?.phoneNumber,
        phoneNumber
      );

      setContactPhoneNumber(numeroDeContacto || PERFIL.whatsapp);
    } catch (error) {
      mostrarMensajeError("Error al obtener el proveedor", error.message);
    }
  };

  const obtenerContacto = async () => {
    try {
      const contactoResponse = await getContacto();
      if (contactoResponse.statusresponse) {
        const whatsappContact = contactoResponse.data.find(
          (contacto) => contacto.seccion === "WhatsApp"
        );

        if (whatsappContact && size(whatsappContact.contactos) > 0) {
          const contactoWhatsApp = whatsappContact.contactos[0].contacto;
          setContactPhoneNumber(contactoWhatsApp);
        } else {
          mostrarMensajeError(
            "No se encontró información de WhatsApp",
            "No se pudo encontrar el contacto de WhatsApp en la respuesta."
          );
        }
      }
    } catch (error) {
      mostrarMensajeError(
        "Error al obtener información de contacto",
        "Hubo un problema al obtener la información de contacto. Por favor, inténtalo de nuevo más tarde."
      );
    }
  };

  // useEffect(() => {
  //   const verifyToken = async () => {
  //     try {
  //       const token = await getToken(appCheck, true);
  //       if (token) {
  //         setIsAppCheckValid(true);
  //       } else {
  //         setIsAppCheckValid(false);
  //       }
  //     } catch (error) {
  //       setIsAppCheckValid(false);
  //     }
  //   };

  //   verifyToken();

  //   const unsubscribe = onTokenChanged(appCheck, (token) => {
  //     if (token) {
  //       setIsAppCheckValid(true);
  //     } else {
  //       setIsAppCheckValid(false);
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    let unsubscribe;
    if (user) {
      fetchNotificaciones();
    }

    async function fetchNotificaciones() {
      try {
        unsubscribe = getNotificacionesStatusRealTime(
          user?.uid,
          "NO_VISTA",
          (response) => {
            setNotificaciones(response.data);
          }
        );
      } catch (error) {
        mostrarMensajeError(
          "Error al obtener notificaciones",
          "Hubo un problema al obtener las notificaciones. Por favor, inténtalo de nuevo más tarde."
        );
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  useEffect(() => {
    const unsubscribe = obtenerMantenimiento();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const obtenerMantenimiento = () => {
    try {
      return getMantenimientoStatus((response) => {
        setMantenimiento(response.data[0]);
      });
    } catch (error) {
      mostrarMensajeError(
        "Error al obtener el detalle del mantenimiento",
        "Hubo un problema al intentar obtener el estado de mantenimiento. Por favor, inténtalo de nuevo más tarde."
      );
    }
  };

  useEffect(() => {
    if (
      membresia &&
      membresia.nombre !== "Gratis" &&
      rol.nombre !== "ADMINISTRADOR"
    ) {
      if (diasRenovacionMembresia <= 0) {
        mostrarMensajePregunta(
          "Renovación de membresía requerida",
          "Tu membresía ha vencido. Por favor, ponte en contacto con nuestro equipo de soporte para renovarla.",
          "Contactar a Soporte",
          "Cancelar"
        ).then((res) => {
          if (res.isConfirmed) {
            window.location.href = "/#/soporte/contacto";
          }
        });
      } else if (diasRenovacionMembresia <= 5) {
        mostrarMensajePregunta(
          "Aviso: Renovación de membresía",
          `Te quedan ${diasRenovacionMembresia} días para renovar tu membresía. Por favor, ponte en contacto con nuestro equipo de soporte para renovarla.`,
          "Contactar a Soporte",
          "Cancelar"
        ).then((res) => {
          if (res.isConfirmed) {
            window.location.href = "/#/soporte/contacto";
          }
        });
      }
    }
  }, [diasRenovacionMembresia, membresia, rol]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        validarSesion(async (userData) => {
          if (userData) {
            setUser(userData);
            const unsubscribe = await obtenerRegistroxIDTiempoReal(
              "USUARIOS",
              userData.uid,
              async (dataOfUser) => {
                if (dataOfUser.statusresponse) {
                  setUserData(dataOfUser.data);
                  if (dataOfUser?.data?.fechaRenovacionMembresia) {
                    setDiasRenovacionMembresia(
                      calcularDiasRenovacionMembresia(
                        dataOfUser?.data?.fechaRenovacionMembresia
                      )
                    );
                  }
                  if (dataOfUser.data.type) {
                    try {
                      const rolSnapshot = await getDoc(dataOfUser.data.type);
                      const userRol = rolSnapshot.data();
                      setRol({ ...userRol, id: rolSnapshot.id });
                    } catch (error) {
                      mostrarMensajeError(
                        "Error al obtener la información",
                        "Hubo un problema al intentar obtenerla información. Por favor, inténtalo de nuevo más tarde."
                      );
                    }
                  }

                  if (dataOfUser.data.membresia) {
                    try {
                      const membresiaSnapshot = await getDoc(
                        dataOfUser.data.membresia
                      );
                      const userMembresia = membresiaSnapshot?.data();

                      setMembresia(userMembresia);
                    } catch (error) {
                      mostrarMensajeError(
                        "Error al obtener la membresía",
                        "Hubo un problema al intentar obtener la membresía del usuario. Por favor, inténtalo de nuevo más tarde."
                      );
                    }
                  }
                }
                setTimeout(() => {
                  setIsLoading(false);
                }, 1000);
              }
            );
            return unsubscribe;
          } else {
            if (
              ![
                "#/registro/registro-proveedor",
                "#/registro",
                "#/login",
              ].includes(window.location.hash) &&
              isSorteosHerraduraRojaTheme()
            ) {
              setTimeout(() => {
                setIsLoading(false);
                mostrarMensajePregunta(
                  "¡Bienvenido a Sorteos Herradura Roja!",
                  "¿Listo para vivir la emoción de nuestros sorteos? Para disfrutar al máximo de todas las funciones y beneficios, te invitamos a iniciar sesión o registrarte ahora. Así podrás comprar boletos y sumergirte en la experiencia completa de Sorteos Herradura Roja.",
                  "Crear Cuenta o Iniciar Sesión",
                  "Continuar sin Cuenta"
                ).then((res) => {
                  if (res.isConfirmed) {
                    window.location.href = "/#/login";
                  } else {
                    signInAnonymouslyWithFirebase();
                  }
                });
              }, 1500);
            } else if (!isSorteosHerraduraRojaTheme()) {
              signInAnonymouslyWithFirebase();
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          }
        });
      } catch (error) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const obtenerMiUbicacion = async () => {
      try {
        const miUbicacion = await obtenerUbicacionActual();
        setUbicacion(miUbicacion);
      } catch (error) {
        // mostrarMensajeError(
        //   "Error al obtener tu ubicación",
        //   "Hubo un problema al intentar obtener tu ubicación actual. Por favor, asegúrate de tener activada la geolocalización en tu dispositivo e inténtalo de nuevo más tarde."
        // );
      }
    };

    obtenerMiUbicacion();
  }, []);

  if (userData?.status === "BLOQUEADO") {
    return <BlockedUserScreen />;
  }

  if (
    mantenimiento &&
    mantenimiento.activo &&
    mantenimiento.status === "ACTIVO"
  ) {
    return <MantenimientoScreen mantenimiento={mantenimiento} />;
  }

  if (isLoading) {
    return <LoadingHome contactPhoneNumber={contactPhoneNumber} />;
  }

  // if (isAppCheckValid === null || !isAppCheckValid) {
  //   return <LoadingHome />;
  // }

  return (
    <HashRouter>
      <HelmetMetaData />
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="*"
            element={
              <>
                {showConfetti && (
                  <Confetti style={{ zIndex: 9999 }} colors={colors} />
                )}
                <DefaultLayout
                  user={user}
                  setShowConfetti={setShowConfetti}
                  setLoadingProcess={setLoadingProcess}
                  userData={userData}
                  rol={rol}
                  membresia={membresia}
                  ubicacion={ubicacion}
                  setUbicacion={setUbicacion}
                  diasRenovacionMembresia={diasRenovacionMembresia}
                  notificaciones={notificaciones}
                  mantenimiento={mantenimiento}
                  contactPhoneNumber={contactPhoneNumber}
                />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <Login setLoadingProcess={setLoadingProcess} user={user} />
            }
          />
          <Route
            path="/registro/registro-proveedor"
            element={
              <Register
                setLoadingProcess={setLoadingProcess}
                user={user}
                setShowConfetti={setShowConfetti}
                rol={rol}
              />
            }
          />
          <Route
            path="/registro"
            element={
              <Register
                setLoadingProcess={setLoadingProcess}
                setShowConfetti={setShowConfetti}
                user={user}
                rol={rol}
              />
            }
          />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route
            path="/cuenta/configuracion/restablecer"
            element={
              <RecuperarContraseña setLoadingProcess={setLoadingProcess} />
            }
          />
          <Route
            path="/restablecer"
            element={
              <RecuperarContraseña setLoadingProcess={setLoadingProcess} />
            }
          />
        </Routes>
      </Suspense>
      <LoadingProcess isLoading={loadingProcess} />
    </HashRouter>
  );
}

export default App;
